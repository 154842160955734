<template>
  <div v-if="pageReady" class="">
    <h2 class="fs-2 my-4 text-primary text-center">
      Employment Medicine TPD Referral
    </h2>
    <form
      class="vue-form"
      enctype="multipart/form-data"
      @submit.prevent="submit"
    >
      <fieldset>
        <div class="d-flex flex-column container align-items-start mb-5 p-0">
          <h2 class="pt-5 my-5 fs-3 text-dark">Patient's Details</h2>
          <div class="w-100 d-flex justify-content-between">
            <Select2
              v-model="existingPatient"
              class="d-flex flex-grow-1"
              :label-text="`Please search for existing patient's name:`"
              :data-array="patientList"
              :api-call-name="`getTPDPatientList`"
              :tpd-mode="true"
              :input-name="`Patient`"
            >
              <template #list-footer>
                <div
                  class="d-flex justify-content-between align-items-center p-2 border-top"
                >
                  <div class="mt-2">
                    <span class="fw-bold d-block">Can't find a patient?</span>
                    <span class="text-gray-600 fw-light">
                      You can add patient’s details manually to upload a new
                      patient data.
                    </span>
                  </div>
                </div>
              </template>
              <template #option="option">
                <div
                  v-if="option"
                  class="py-3 px-2 vs-dropdown-listing rounded-3"
                >
                  <span class="d-block fw-bold text-capitalize">
                    {{ option.first_name }}
                  </span>
                  <em>
                    {{
                      option.gender === 'M'
                        ? 'Male'
                        : option.gender === 'F'
                        ? 'Female'
                        : 'Not Specified'
                    }}
                    , Born in {{ option.date_of_birth }}
                  </em>
                </div>
              </template>
            </Select2>
          </div>
        </div>

        <!-- patient details section -->
        <form
          id="patient_info_section"
          ref="patientSection"
          class="card container my-5 p-4 shadow-sm"
        >
          <span
            class="position-absolute top-0 start-10 translate-middle bg-primary p-2 px-5 border border-light rounded-pill text-white"
          >
            <strong>{{ displayedPatientData.id ? 'Selected' : 'New' }}</strong>
          </span>
          <div class="my-4 row">
            <div class="col">
              <FormInput
                v-model:modelValue="displayedPatientData.first_name"
                :disabled="patientDetailsState === 'Selected'"
                :error="patientErrors.first_name"
                :label="`patient_first_name`"
              >
                <label class="form-check-label" for="patient_first_name">
                  First Name
                  <BaseRequiredLabel />
                </label>
              </FormInput>
            </div>

            <div class="col">
              <FormInput
                v-model:modelValue="displayedPatientData.middle_name"
                :disabled="patientDetailsState === 'Selected'"
                :error="patientErrors.middle_name"
                :label="`patient_middle_name`"
              >
                <label class="form-check-label" for="patient_middle_name">
                  Middle Name
                </label>
              </FormInput>
            </div>

            <div class="col">
              <FormInput
                v-model:modelValue="displayedPatientData.last_name"
                :disabled="patientDetailsState === 'Selected'"
                :error="patientErrors.last_name"
                :label="`patient_last_name`"
              >
                <label class="form-check-label" for="patient_last_name">
                  Last Name
                  <BaseRequiredLabel />
                </label>
              </FormInput>
            </div>
          </div>

          <div class="my-4 row">
            <div class="col">
              <BaseSelectInput
                v-model="displayedPatientData.gender"
                :label="`Gender`"
                :options="genderOptions"
                :disabled="patientDetailsState === 'Selected'"
                :required="true"
              />
            </div>
            <div class="col">
              <label class="form-check-label" for="patient_date_of_birth">
                Date of Birth
                <BaseRequiredLabel />
              </label>

              <datepicker
                id="patient_date_of_birth"
                v-model="displayedPatientData.date_of_birth"
                :model-value="
                  convertToDateFormat(displayedPatientData.date_of_birth)
                "
                autocomplete="off"
                input-format="dd MMMM yyyy"
                :upper-limit="currentYear"
                class="datepicker-input-box has-validation"
                :class="patientErrors.date_of_birth ? 'border-danger' : ''"
                style="
                  --vdp-selected-bg-color: #32c3d6;
                  --vdp-hover-bg-color: #32c3d6;
                "
                starting-view="year"
                :disabled="patientDetailsState === 'Selected'"
              />
              <div v-if="patientErrors.date_of_birth" class="error-warning">
                {{ patientErrors.date_of_birth?.[0] }}
              </div>
            </div>

            <div class="col">
              <FormInput
                v-model:modelValue="displayedPatientData.contact_number"
                :disabled="patientDetailsState === 'Selected'"
                :error="patientErrors.contact_number"
                :label="`contact_number`"
                :type="`text`"
              >
                <label class="form-check-label" for="patient_contact_num">
                  Contact Number
                  <BaseRequiredLabel />
                </label>
              </FormInput>
            </div>
          </div>

          <div class="my-4 row">
            <div class="col">
              <FormInput
                v-model:modelValue="displayedPatientData.emr_number"
                :disabled="patientDetailsState === 'Selected'"
                :error="patientErrors.emr_number"
                :label="`emr_number`"
                :type="`number`"
              >
                <label class="form-check-label" for="patient_emr_number">
                  EMR Number
                </label>
              </FormInput>
            </div>
            <div class="col">
              <FormInput
                v-model:modelValue="displayedPatientData.claim_number"
                :disabled="patientDetailsState === 'Selected'"
                :error="patientErrors.claim_number"
                :label="`Suburb`"
                :type="`number`"
              >
                <label class="form-check-label" for="patient_claim_number">
                  Membership/Claim Number
                </label>
              </FormInput>
            </div>

            <div class="col">
              <FormInput
                v-model:modelValue="displayedPatientData.email"
                :disabled="patientDetailsState === 'Selected'"
                :error="patientErrors.email"
                :label="`Email`"
                :type="`email`"
              >
                <label class="form-check-label" for="patient_email">
                  Email
                  <BaseRequiredLabel />
                </label>
              </FormInput>
            </div>
          </div>

          <div class="my-4 row">
            <div class="col">
              <FormTextArea
                id="patient_address"
                v-model="displayedPatientData.street_address"
                :disabled="patientDetailsState === 'Selected'"
                :error="patientErrors.street_address"
              >
                <label class="form-check-label" for="patient_address">
                  Address
                  <BaseRequiredLabel />
                </label>
              </FormTextArea>
            </div>
          </div>

          <div class="my-4 row">
            <div class="col">
              <FormInput
                v-model:modelValue="displayedPatientData.suburb"
                :disabled="patientDetailsState === 'Selected'"
                :error="patientErrors.suburb"
                :label="`Suburb`"
              >
                <label class="form-check-label" for="patient_suburb">
                  Suburb
                  <BaseRequiredLabel />
                </label>
              </FormInput>
            </div>
            <div class="col">
              <FormInput
                v-model:modelValue="displayedPatientData.state"
                :disabled="patientDetailsState === 'Selected'"
                :error="patientErrors.state"
                :label="`State`"
              >
                <label class="form-check-label" for="patient_state">
                  State
                  <BaseRequiredLabel />
                </label>
              </FormInput>
            </div>
            <div class="col">
              <FormInput
                v-model:modelValue="displayedPatientData.country"
                :disabled="patientDetailsState === 'Selected'"
                :error="patientErrors.country"
                :label="`Country`"
              >
                <label class="form-check-label" for="patient_country">
                  Country
                  <BaseRequiredLabel />
                </label>
              </FormInput>
            </div>
            <div class="col">
              <FormInput
                v-model:modelValue="displayedPatientData.postcode"
                :disabled="patientDetailsState === 'Selected'"
                :error="patientErrors.postcode"
                :label="`ZIP/Postal Code`"
              >
                <label class="form-check-label" for="patient_postal_code">
                  ZIP/Postal Code
                  <BaseRequiredLabel />
                </label>
              </FormInput>
            </div>
          </div>
        </form>

        <!-- lawyer tpd selection section -->
        <div class="my-5 container">
          <div class="row">
            <div class="col-5">
              <h2 class="pt-5 my-5 fs-3 text-dark">
                Report Format Requirement
              </h2>
              <label
                class="form-check-label d-block mb-3 text-gray-600"
                for="reportFormat"
              >
                Please click on the required report:
              </label>
              <div id="report-format" class="fw-light">
                <BaseCheckboxGroup
                  v-model="reportFormat"
                  :options="reportFormatOptions"
                  :inline="false"
                  :label="`reportFormat`"
                />
              </div>

              <div
                v-if="reportFormat.includes('Others')"
                class="mt-1 d-flex flex-column"
              >
                <div class="my-2">
                  <img src="@/assets/svg/warning.svg" width="10" />
                  <span class="text-gray-600 warning-font-size">
                    Please make sure the new file input name is named correctly.
                  </span>
                </div>

                <VueUploadComponent
                  ref="newReportUpload"
                  v-model="newTpd"
                  class="file-upload-btn w-50 enable-pointer"
                  :multiple="true"
                  :size="1024 * 1024 * 10"
                  name="newReportUpload"
                  @input-file="inputFile"
                >
                  <button
                    type="button"
                    class="border-0 text-white bg-primary px-3 py-2 rounded-3 w-100"
                  >
                    <img src="@/assets/svg/folder.svg" class="me-2" />
                    Upload new report
                  </button>
                </VueUploadComponent>

                <div class="my-5">
                  <span class="form-check-label d-block mb-3 text-gray-600">
                    New reports:
                  </span>
                  <div
                    v-for="(report, idx) in newTpd"
                    :key="report"
                    class="d-flex flex-column my-1"
                  >
                    <div class="d-flex align-items-center">
                      <span>
                        {{ idx + 1 }}.
                        {{
                          report.display_name
                            ? report.display_name
                            : report.name
                        }}
                      </span>

                      <div v-if="report.name">
                        <input
                          :id="
                            report.display_name
                              ? report.display_name + idx
                              : report.name + idx
                          "
                          v-model="toggleNewReportNameChange"
                          type="checkbox"
                          :value="idx"
                          class="btn-check"
                          autocomplete="off"
                        />
                        <label
                          class="border-0 bg-transparent text-danger btn-sm py-0 px-1 ms-2 btn"
                          :for="
                            report.display_name
                              ? report.display_name + idx
                              : report.name + idx
                          "
                        >
                          <img
                            src="@/assets/svg/edit.svg"
                            alt="edit-icon"
                            width="10"
                          />
                        </label>
                      </div>

                      <button
                        type="button"
                        class="btn border-0 bg-transparent text-danger btn-sm py-0"
                        @click="storeDocToRemove(idx)"
                      >
                        x
                      </button>
                    </div>
                    <div
                      v-if="toggleNewReportNameChange.includes(idx)"
                      class="d-flex flex-column my-3"
                    >
                      <label class="form-label">File rename:</label>
                      <div class="d-flex align-items-center">
                        <input
                          v-if="report.file_name"
                          v-model="report.file_name"
                          class="form-control me-2"
                          type="text"
                          maxlength="150"
                        />
                        <input
                          v-else
                          v-model="report.name"
                          class="form-control me-2"
                          type="text"
                          maxlength="150"
                        />
                        <button
                          type="button"
                          class="btn btn-sm btn-success"
                          @click="removeFromNameChangeList(idx)"
                        >
                          <img
                            src="@/assets/svg/save.svg"
                            alt="save-icon"
                            class="icon-white"
                            width="15"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col ms-5 ps-5">
              <h2 class="pt-5 my-5 fs-3 text-dark">Service Requirements</h2>

              <label
                class="form-check-label d-block mb-3 text-gray-600"
                for="serviceRequirements"
              >
                Please click on the services below:
              </label>
              <div id="required-service" class="fw-light">
                <BaseRadioGroup
                  v-model="serviceRequirements"
                  :label="`serviceRequirements`"
                  :options="serviceRequirementsOptions"
                />
              </div>

              <div
                v-if="
                  serviceRequirements.length > 0 &&
                  !serviceRequirements.includes('SPIC')
                "
                class="my-3 fw-light"
              >
                <label class="form-check-label d-block">
                  <input
                    v-model="additionalServices"
                    type="checkbox"
                    class="form-check-input me-1"
                    value="MAS"
                  />
                  Additional MAS report
                </label>

                <label class="form-check-label d-block">
                  <input
                    v-model="additionalServices"
                    type="checkbox"
                    class="form-check-input me-1"
                    value="IME"
                  />
                  Additional IME report
                </label>
              </div>

              <h2 class="pt-5 my-5 fs-3 text-dark">Fees</h2>

              <label
                class="form-check-label d-block mb-3 text-gray-600"
                for="serviceRequirements"
              >
                Please select a fees type:
              </label>
              <div id="fees" class="fw-light">
                <BaseRadioGroup
                  v-model="fees"
                  :label="`fees`"
                  :options="filteredFeesOption"
                />
              </div>

              <!-- <button
                class="mt-5 border-0 text-white bg-primary px-4 py-2 rounded-3 d-flex align-items-center"
              >
                <img src="@/assets/svg/folder.svg" class="me-2" />
                <span>Upload patient documents</span>
              </button> -->
            </div>
          </div>
        </div>

        <div class="d-grid col-4 mt-5 mx-auto">
          <button
            type="submit"
            class="btn btn-success rounded-3 text-white my-3"
            :disabled="isSubmitting"
          >
            <div v-if="isSubmitting" class="spinner-border" role="status">
              <span class="sr-only"></span>
            </div>
            <span v-else>SAVE REFERRAL</span>
          </button>
        </div>
      </fieldset>
    </form>
  </div>
  <div
    v-else
    class="min-vh-100 d-flex justify-content-center align-items-center"
  >
    <div>
      <div
        class="spinner-border text-primary"
        style="width: 12rem; height: 12rem"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    id="confirmationModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="confirmationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog text-center">
      <div class="modal-content">
        <div class="modal-body py-5">
          <span>Are you sure you want to delete</span>
          <br />
          <span class="fw-bold">
            "
            {{ docToRemove.name ? docToRemove.name : docToRemove.display_name }}
            "
          </span>
          <span>from list of newly uploaded TPD format(s) ?</span>
          <p class="mb-0 mt-1">You can't undo this action.</p>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button
            type="button"
            class="btn bg-gray-300 me-4"
            data-bs-dismiss="modal"
            @click="docToRemove = null"
          >
            Cancel
          </button>
          <button
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-danger text-white"
            @click="delFile"
          >
            Delete file
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import VueUploadComponent from 'vue-upload-component'
import { Modal } from 'bootstrap'
import BaseSelectInput from '@/components/Base/BaseSelectInput.vue'
import BaseRadioGroup from '@/components/Base/BaseRadioGroup.vue'
import BaseCheckboxGroup from '@/components/Base/BaseCheckboxGroup.vue'
import BaseRequiredLabel from '@/components/Base/BaseRequiredLabel.vue'
import FormInput from '@/components/TPD/Components/FormInput.vue'
import FormTextArea from '@/components/TPD/Components/FormTextArea.vue'

import Select2 from '@/components/Widget/SelectTwo.vue'
import dayjs from 'dayjs'

import { getLastDayOfYear, convertDateToAuFormat } from '@/utils/generics.js'

// import { parseISO } from 'date-fns'

export default {
  name: 'TpdLawyerForm',
  components: {
    BaseRadioGroup,
    BaseCheckboxGroup,
    Datepicker,
    FormInput,
    Select2,
    BaseRequiredLabel,
    BaseSelectInput,
    FormTextArea,
    VueUploadComponent,
  },
  props: {
    assessmentId: { type: Number, default: null },
    readyNext: { type: Boolean },
    oldAssessmentData: {
      type: Object,
      default: null,
    },
  },

  emits: ['update:readyNext', 'update:toastText'],

  data() {
    return {
      existingPatient: null,
      newPatient: {
        country: 'Australia',
      },
      patientErrors: {},
      //
      patientList: [],

      //
      reportFormat: [],
      newReportFormat: [],
      newTpd: [],

      //
      toggleNewReportNameChange: [],

      reportFormatOptions: [],
      //
      serviceRequirements: [],
      serviceRequirementsOptions: [],
      //
      fees: null,
      feesOptions: [],
      //
      additionalServices: [],
      //
      showReportWarning: false,
      //
      isNewForm: true,
      interpreterOptions: [
        {
          value: true,
          label: 'Yes',
        },
        {
          value: false,
          label: 'No',
        },
      ],
      genderOptions: [
        {
          value: 'M',
          label: 'Male',
        },
        {
          value: 'F',
          label: 'Female',
        },
      ],
      //
      docToRemove: {
        display_name: '',
      },
      //
      toasts: [],

      //
      pageReady: false,
      isSubmitting: false,
    }
  },

  computed: {
    currentYear() {
      return getLastDayOfYear()
    },

    displayedPatientData: {
      //this is to control display of patient data because select2 plugin doesnt seem to support single clear option, the events arent being emited

      //this extra step is because vue throws an error about not able to read data inside null object when deselecting patient from list

      get() {
        if (this.existingPatient) {
          return this.existingPatient
        }
        //not the best way to do inside computed property
        else {
          this.clearError
          return this.newPatient
        }
      },
      // setter
      set(newValue) {
        this.newPatient = newValue
        return newValue
      },
    },

    filteredFeesOption() {
      var array = [...this.feesOptions]

      if (this.serviceRequirements.includes('DCR')) {
        // File review, doctor consultation & MAS report (MAS) is selected then reduced fees tier 3 is not available
        array.splice(3, 1)
      } else if (this.serviceRequirements.includes('DCDSR')) {
        // File review, doctor consultation & MAS report (MAS) & specialist MAS report is selected then reduced fee tier 3 and tier 2 are not available
        array.splice(2, 2)
      } else {
        array = this.feesOptions
      }
      return array
    },

    patientDetailsState() {
      if (this.displayedPatientData.id) {
        return 'Selected'
      } else return 'New'
    },
  },

  created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        // react to route changes...

        if (previousParams.id !== toParams.id && toParams.id === 'new') {
          //reset vuex store
          this.$store.commit('STORE_TPDREFERRAL', null)

          //reset reactive data in this page

          this.existingPatient = null

          this.reportFormat = []

          this.serviceRequirements = []

          this.additionalServices = []

          this.newTpd = []
          this.toggleNewReportNameChange = []

          this.fees = null
        } else {
          this.$router.go(0)
        }
      }
    )
  },

  async mounted() {
    await this.getPatientList()
    await this.getTPDTemplates()
    //map retrieved data from referral
    this.mapExistingData()

    this.pageReady = true
  },

  methods: {
    parseStringToDate(date) {
      return new Date(dayjs(date))
    },

    removeFromNameChangeList(idx) {
      let foundIdx = this.toggleNewReportNameChange.indexOf(idx)
      this.toggleNewReportNameChange.splice(foundIdx, 1)
    },

    inputFile() {
      this.newTpd.forEach((file, idx) =>
        file.display_name ? '' : this.toggleNewReportNameChange.push(idx)
      )
    },

    //this updates the file to be removed
    storeDocToRemove(idx) {
      //bootstrap scripts

      const myModal = new Modal('#confirmationModal')

      this.docToRemove = { index: idx, ...this.newTpd[idx] }

      return myModal.show()
    },

    //this triggers the del file api
    async delFile() {
      //if have id then remove with api call
      let value = {
        id: this.docToRemove.id,
      }

      //lets delete the file on frontend first
      this.newTpd.splice(this.docToRemove.index, 1)

      await this.$store.dispatch('delTPDNewReport', value).catch((error) => {
        console.log(error)
      })
    },

    formatDate(date) {
      if (date) {
        return convertDateToAuFormat(date)
      } else return null
    },

    mapExistingData() {
      if (this.$store.state.TPDReferral) {
        this.existingPatient = this.$store.state.TPDReferral.patient

        this.reportFormat =
          this.$store.state.TPDReferral.report_format_requirements
        this.serviceRequirements =
          this.$store.state.TPDReferral.service_requirement.service_type

        //map additional services
        this.$store.state.TPDReferral.service_requirement.ime_report
          ? this.additionalServices.push('IME')
          : false
        this.$store.state.TPDReferral.service_requirement.mas_report
          ? this.additionalServices.push('MAS')
          : false

        //map fees
        this.fees = this.$store.state.TPDReferral.fee_structure_type

        //map new_report
        if (this.$store.state.TPDReferral.new_reports.length > 0) {
          this.reportFormat.push('Others')

          this.newTpd = this.$store.state.TPDReferral.new_reports.map(
            (file) => {
              return { ...file }
            }
          )
        }
      } else console.log('no existing data found...')
    },

    convertToDateFormat(date) {
      if (date) {
        const result = new Date(date)

        return result
      } else return null
    },

    clearError() {
      this.patientErrors = {}
    },

    async getPatientList() {
      let payload = { page: 1 }
      await this.$store
        .dispatch('getTPDPatientList', payload)
        .then((response) => {
          let data = response.data

          this.patientList = data.results
        })
    },

    async getTPDTemplates() {
      await this.$store
        .dispatch('getTPDTemplates')
        .then((response) => {
          //store to vuex

          this.$store.state.TPDTemplates = response.data

          //filter formats

          this.reportFormatOptions =
            this.$store.state.TPDTemplates.report_format_requirements_choices.map(
              (item) => {
                return {
                  value: item.id,
                  label: item.display_name,
                }
              }
            )

          //to add other option in report formats, to allow future lawyers to upload their own tpds
          this.reportFormatOptions.push({
            value: 'Others',
            label: 'Others',
          })

          this.feesOptions = this.$store.state.TPDTemplates.fee_choices.map(
            (item) => {
              return {
                value: item.fee_type,
                label: item.fee_display_name,
              }
            }
          )

          //service requirments
          this.serviceRequirementsOptions =
            this.$store.state.TPDTemplates.service_requirement_choices.map(
              (item) => {
                return {
                  value: item.service_type,
                  label: item.service_display_name,
                }
              }
            )
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async postNewReferral(payload) {
      //update payload when creating new referral

      await this.$store
        .dispatch('postTPDNewReferral', payload)
        .then((response) => {
          this.$store.commit('STORE_TPDREFERRAL', response.data)

          // compile toast text
          this.toasts.push({
            status: 'success',
            content: 'New TPD referral created successfully!',
          })

          // Trigger toast text
          this.$root.toasts = this.toasts

          // Delay for the success toast
          setTimeout(
            () =>
              this.$router.push({
                name: 'referral-information-uploads',
                params: { id: response.data.id },
              }),
            1800
          )
        })
        .catch((error) => {
          if (error.response) {
            // Trigger toast text
            this.$root.toasts.push({
              status: 'danger',
              content: `Error: ${error.response.status}. Something went wrong when creating new referral`,
            })
          } else if (error.request) {
            console.log(error.request)
          } else {
            alert(`Error: ${error.message}`)
          }
        })
    },

    async patchTPDReferral(payload) {
      await this.$store
        .dispatch('patchTPDReferral', payload)
        .then((response) => {
          //store referral ID for patching

          this.$store.commit('STORE_TPDREFERRAL', response.data)

          // compile toast text
          this.toasts.push({
            status: 'success',
            content: `TPD referral ${this.$route.params.id} updated successfully!`,
          })

          // Trigger toast text
          this.$root.toasts = this.toasts

          // Delay for the success toast
          setTimeout(
            () =>
              this.$router.push({
                name: 'referral-information-uploads',
                params: { id: this.$store.state.TPDReferral.id },
              }),
            1800
          )
        })
        .catch((error) => {
          if (error.response) {
            // Trigger toast text
            this.$root.toasts.push({
              status: 'danger',
              content: `Error: ${error.response.status}. Something went wrong when updating referral`,
            })
          } else if (error.request) {
            console.log(error.request)
          } else {
            alert(`Error: ${error.message}`)
          }
        })
    },

    async submit() {
      this.isSubmitting = true

      // update new patient data first if there is new
      if (!this.displayedPatientData.id) {
        //format dates for backend
        this.displayedPatientData.date_of_birth = this.formatDate(
          this.displayedPatientData.date_of_birth
        )

        //submit new patient if no ID detected
        await this.$store
          .dispatch('postTPDNewPatient', this.displayedPatientData)
          .then((response) => {
            //update patient data
            this.displayedPatientData = response.data

            // compile toast text
            this.toasts.push({
              status: 'success',
              content: 'New patient created!',
            })

            this.$router.push({
              name: 'referral-information-uploads',
              params: { id: this.$store.state.TPDReferral.id },
            })
          })

          .catch((error) => {
            if (error.response) {
              this.patientErrors = error.response.data
              this.$refs.patientSection.scrollIntoView()

              // Trigger toast text
              this.$root.toasts.push({
                status: 'warning',
                content: `Incorrect patient details found.`,
              })
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request)
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message)
            }
            // Scroll to patient section for error
          })
          .finally(() => {
            this.isSubmitting = false
          })
      }

      if (!this.displayedPatientData.id) {
        // Cancel the below function if the patient
        // isn't created

        // Trigger toast text
        this.$root.toasts.push({
          status: 'danger',
          content: `Please ensure patient is created or selected`,
        })
        return false
      } else {
        // compile toast text
        this.toasts.push({
          status: 'success',
          content: 'Patient updated successfully!',
        })
      }

      //remove OTHERS from report format
      this.reportFormat = this.reportFormat.filter((x) => x !== 'Others')

      //base payload
      let payload = {
        service_requirement_type: this.serviceRequirements,
        patient_id: this.displayedPatientData.id,
        service_requirement_ime_report: false,
        service_requirement_mas_report: false,
        fee_structure_type: this.fees,
      }

      //if user attempts to create new referral then do this
      if (!this.$store.state.TPDReferral && this.$route.params.id === 'new') {
        payload.patient_id = this.displayedPatientData.id
      }

      //map new names for newly added reports!
      this.newReportFormat.length > 0
        ? (payload.new_report_display_names = this.newReportFormat)
        : ''

      //map overall
      this.reportFormat.length > 0
        ? (payload.report_format_requirements = this.reportFormat)
        : ''

      //map additional services if there is inputs
      if (this.additionalServices.length > 0) {
        this.additionalServices.includes('MAS')
          ? (payload.service_requirement_mas_report = true)
          : null
        this.additionalServices.includes('IME')
          ? (payload.service_requirement_ime_report = true)
          : null
      }

      //convert to FormData due to multipart Data format
      var form_data = new FormData()

      for (var key in payload) {
        form_data.append(key, payload[key])
      }

      //append new_reports file
      //append the name changes for new_reports

      if (this.newTpd.length > 0) {
        // let fileNameArray = []
        this.newTpd.forEach((file) => {
          //this ensure to only upload file type formats, and not reupload existing data which is not file type format
          if (file.fileObject) {
            form_data.append(`new_reports`, file.file)
            form_data.append(
              `new_report_display_names`,
              file.display_name ? file.display_name : file.name
            )
          }

          // fileNameArray.push(file.display_name ? file.display_name : file.name)
        })

        // form_data.append(`new_reports_display_name`, fileNameArray)
      }

      //alert if user did not input required data
      if (this.serviceRequirements.length === 0) {
        alert('Please select one required service')
      } else if (this.reportFormat === 0) {
        alert('Please select at least 1 TPD report format')
      } else {
        let final = {
          payload: form_data,
        }
        if (this.$store.state.TPDReferral) {
          //if exists, then patch instead
          final.id = this.$store.state.TPDReferral.id
          await this.patchTPDReferral(final)
        } else {
          //create a new one
          await this.postNewReferral(final)
        }
      }

      this.isSubmitting = false
    },
  },
}
</script>

<style lang="scss" scoped>
#createReferralBtn {
  max-width: 250px;
}

.disabled {
  &-textarea {
    &:disabled {
      margin-top: 10px;
      background-color: white !important;

      border: 0.5px solid #45b1aa;
    }
  }
}

.enable-pointer {
  cursor: pointer;
}

.icon-white {
  filter: invert(100%) sepia(100%) saturate(24%) hue-rotate(3deg)
    brightness(107%) contrast(106%);
}
</style>
